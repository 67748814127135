// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  debug: true,
  apiUrlKeola: 'https://i3usmm8f0i.execute-api.us-east-1.amazonaws.com/v1',
  apiNewUrlKeola: 'https://z5a1sdshr6.execute-api.us-east-1.amazonaws.com/v1',
  paypalKey:
    'Ac-SUs9_UC-hGq5-Ne2r_z6nuco1qRWZArDgjXZlCkNGKfV36z0UfexlS7jVHpYtGUzMXbu_GfnFmFdT',
  culqiPublicKey: 'pk_test_bb6e206ad63772be',
  culqiSecretKey: 'sk_test_0u7P0QpI9P1Lvx1q',
  apiKeyMap:
    'pk.eyJ1IjoicmFsZXhhbmRlcnZjIiwiYSI6ImNsamVyYzkwYzBrdnIzbG56eDF3aXZ1amEifQ.u_zPsf47GUitN2LaxPw4Rg',
  x_api_key: 'VIe5XRQMEL3hHEPBFbIE74KMz6UMGRH5REa4Zk3c',
  keola_url: 'dev.keolalottery.club/lottery',

  // production: false,
  // debug: true,
  //
  // apiUrlKeola: 'https://2w9ee3hx5j.execute-api.us-east-1.amazonaws.com/prod',
  // paypalKey: 'ATL29tDn8b2sPbpeObj4KjVVy7FhlCNQoBbQgJr4O_50n5WIUu2aChMBC4361iugiHSu7PvLBhwEMkpv',
  // culqiPublicKey: 'pk_live_295fa219a4ede954',
  // culqiSecretKey: 'sk_live_9b16e81c40f058be',
  // apiKeyMap:
  //   'pk.eyJ1IjoicmFsZXhhbmRlcnZjIiwiYSI6ImNsamVyYzkwYzBrdnIzbG56eDF3aXZ1amEifQ.u_zPsf47GUitN2LaxPw4Rg',
  // x_api_key: 'PugJSKSbBTNohCwmyrEv3Z4OoTSsNm282ZfVsoze',
};
