import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PaymentMethodComponent } from '@components/shared/payment-method/payment-method.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@components/pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'lottery',
    loadChildren: () =>
      import('@components/pages/portal/portal.module').then(
        (m) => m.PortalModule
      ),
  },
  {
    path: 'planes',
    loadChildren: () =>
      import('@components/pages/plains/plains.module').then(
        (m) => m.PlainsModule
      ),
  },
  {
    path: 'pagos',
    component: PaymentMethodComponent,
  },
  {
    path: 'mi-perfil',
    loadChildren: () =>
      import('@components/pages/profile/profile.module').then(
        (m) => m.ProfileModule
      ),
    canActivate: [AuthGuard],
  },
  // Ruta comodín para manejar rutas no definidas
  {
    path: '**',
    redirectTo: 'lottery',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
