import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  complaintsBookUrl: string;

  constructor() {}

  ngOnInit(): void {
    this.complaintsBookUrl = environment.complaintsBookUrl;
  }
}
