import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  url = environment.apiUrlKeola;
  newUrl = environment.apiNewUrlKeola; //z5a1sdshr6
  private userSubject: BehaviorSubject<any>;
  public user$: Observable<any>;

  constructor(private http: HttpClient) {
    this.userSubject = new BehaviorSubject<any>(null);
    this.user$ = this.userSubject.asObservable();
  }

  get(module: string, type: string) {
    let url = `${this.url}/${module}/${type}`;
    return this.http.get(url).pipe(map((resp: any) => resp));
  }

  getCountries() {
    return this.http.get(`${this.url}/common/countries`).pipe();
  }

  getUser() {
    let url = `${this.url}/user/find`;
    return this.http.get(url).pipe(
      map((resp: any) => {
        this.userSubject.next(resp);
        return resp;
      })
    );
  }

  updateUserInBackend(user: any) {
    return this.http.put(`${this.url}/user/update`, user).pipe();
  }

  updateUser(user: any): void {
    this.userSubject.next(user);
  }

  getMethodPayments(): Observable<any> {
    const url = `${this.newUrl}/master/search?application=lottery&domain=payment_method_lottery&masterStatus=true&columnStatus=true`;
    return this.http
      .get(url)
      .pipe(map((response: any) => response.data.items[0].columns));
  }
}
