<footer class="position-relative">
  <div class="container">
    <div class="info">
      <div>
        <div class="logo mb-3">
          <img src="assets/images/svg/logo.svg" alt="" />
        </div>

        <div class="d-flex mb-3">
          <a
            href="https://www.facebook.com/keolalottery/"
            target="_blank"
            class="me-4 text-decoration-none color-white"
            ><img src="assets/images/svg/fb.svg" alt="" class="me-2" />KeOla
            Networks</a
          ><a
            href="https://www.instagram.com/keolalottery/"
            target="_blank"
            class="me-4 text-decoration-none color-white"
            ><img
              _ngcontent-cqb-c37=""
              src="assets/images/svg/insta.svg"
              alt=""
              class="me-2"
            />@keolaoficial</a
          >
          <a
            href="https://www.tiktok.com/@keola_lottery"
            target="_blank"
            class="text-decoration-none color-white"
            ><img
              src="assets/images/svg/tiktok.svg"
              alt=""
              class="me-2"
            />@keola_lottery</a
          >
        </div>

        <p>
          Descubre la forma más rápida y conveniente de recibir tus productos
          favoritos directamente en tu puerta.
        </p>
        <p class="font-poppins-semiBold">Número de soporte</p>
        <p class="mb-3">(021) 3352391 - (021) 239 1 222</p>
        <p class="font-poppins-semiBold">Métodos de pago</p>
        <div
          class="d-flex justify-content-center mt-4 justify-content-lg-start"
        >
          <img src="assets/images/svg/visa.svg" alt="" />
          <img src="assets/images/svg/mastercard.svg" class="mx-2" alt="" />
          <img src="assets/images/svg/american.svg" alt="" />
        </div>
      </div>
    </div>

    <div class="about mt-4 mt-lg-0">
      <h6>Sobre Ke0la</h6>
      <a
        class="text-decoration-none pb-3 text-white"
        routerLink="/lottery/sobre-nosotros"
        >Nosotros</a
      >
      <a
        class="text-decoration-none pb-3 text-white"
        routerLink="/lottery/terminos-y-condiciones"
        >Términos y condiciones</a
      >
      <a
        class="text-decoration-none pb-3 text-white"
        routerLink="/lottery/politica-de-cookies"
        >Política de Cookies</a
      >
      <p>Contacto</p>
      <a
        class="text-decoration-none pb-3 text-white"
        href="https://soyaliadocomercial.keola.club/libro-de-reclamaciones"
        target="_blank"
        >Libro de Reclamaciones</a
      >
      <p></p>
    </div>

    <div class="about">
      <h6>Únete a Ke0la</h6>
      <p>Trabaja con Nosotros</p>
      <a
        class="text-decoration-none pb-3 text-white"
        href="https://soykdriver.keola.club/"
        target="_blank"
        >Quiero ser Driver</a
      >
      <p>Haz crecer tu marca</p>
      <a
        class="text-decoration-none text-white"
        href="https://soyaliadocomercial.keola.club/auth/register"
        target="_blank"
        >Registra tu negocio</a
      >
    </div>
  </div>
  <div class="container mt-5">
    <div class="about first-about">
      <h6>Categorías</h6>
      <p>Inicio</p>
      <a
        class="text-decoration-none pb-3 text-white"
        href="https://play.google.com/store/apps/details?id=com.appkeola"
        target="_blank"
        >Bodegas</a
      >
      <a
        class="text-decoration-none pb-3 text-white"
        href="https://play.google.com/store/apps/details?id=com.appkeola"
        target="_blank"
        >Minimarket</a
      >
      <a
        class="text-decoration-none pb-3 text-white"
        href="https://play.google.com/store/apps/details?id=com.appkeola"
        target="_blank"
        >Supermercado</a
      >
      <a
        class="text-decoration-none pb-3 text-white"
        href="https://play.google.com/store/apps/details?id=com.appkeola"
        target="_blank"
        >Restaurante</a
      >
      <a
        class="text-decoration-none pb-3 text-white"
        href="https://play.google.com/store/apps/details?id=com.appkeola"
        target="_blank"
        >Animales</a
      >
      <a
        class="text-decoration-none text-white"
        href="https://play.google.com/store/apps/details?id=com.appkeola"
        target="_blank"
        >Hogar</a
      >
    </div>

    <div class="about mt-4 mt-lg-0">
      <h6>Encuéntranos en estos países</h6>
      <p>Perú</p>
      <p>Colombia</p>
      <p>Panamá</p>
      <p>Ecuador</p>
      <p>Costa Rica</p>
    </div>

    <div class="about">
      <h6>Únete al equipo</h6>
      <p>Registra tu restaurante</p>
      <p>Registra tu Tienda</p>
      <p>Quiero ser Repartidor</p>
      <p>Trabaja con nosotros</p>
      <p>Pídelo ahora</p>
    </div>
  </div>

  <div class="credits">
    <div class="d-flex justify-content-center mb-4">
      <a
        href="https://play.google.com/store/apps/details?id=com.appkeola"
        target="_blank"
      >
        <img src="assets/images/google.png" alt="Play Store" class="me-4" />
      </a>
      <a
        href="https://apps.apple.com/pe/app/appkeola/id6475727503"
        target="_blank"
      >
        <img src="assets/images/ios.png" alt="App Store" />
      </a>
    </div>
    Realizado por KeOla &copy; {{ year }}
  </div>
  <div class="d-flex justify-content-center">
    <span class="ft-8 color-white">v-1.0.0</span>
  </div>
</footer>
