<div style="background-color: #3c3c3c">
  <div class="container">
    <footer class="row row-cols-1 row-cols-sm-4 text-white py-4">
      <div class="col">
        <h6 class="font-bold d-none d-sm-block mb-2">NAVEGACIÓN</h6>
        <ul class="nav flex-column">
          <li class="nav-item d-flex justify-content-between mb-2 font-light">
            <a routerLink="/lottery" class="nav-link p-0 text-white">INICIO</a>
            <img
              role="button"
              routerLink="/lottery"
              src="../../../../assets/images/svg/arrow-right.svg"
              class="d-sm-none"
            />
          </li>
          <li
            class="nav-item d-flex justify-content-between mb-2 pt-2 font-light separator"
          >
            <a routerLink="/lottery/premios" class="nav-link p-0 text-white"
              >PREMIOS</a
            >
            <img
              role="button"
              routerLink="/lottery/premios"
              src="../../../../assets/images/svg/arrow-right.svg"
              class="d-sm-none"
            />
          </li>
          <li
            class="nav-item d-flex justify-content-between mb-2 pt-2 font-light font-light separator"
          >
            <a routerLink="/lottery/ganadores" class="nav-link p-0 text-white"
              >GANADORES</a
            >
            <img
              role="button"
              routerLink="/lottery/ganadores"
              src="../../../../assets/images/svg/arrow-right.svg"
              class="d-sm-none"
            />
          </li>
          <li
            class="nav-item d-flex justify-content-between mb-2 pt-2 font-light separator"
          >
            <a routerLink="/lottery/beneficios" class="nav-link p-0 text-white"
              >BENEFICIOS</a
            >
            <img
              role="button"
              routerLink="/lottery/beneficios"
              src="../../../../assets/images/svg/arrow-right.svg"
              class="d-sm-none"
            />
          </li>
          <li
            class="nav-item d-flex justify-content-between mb-2 pt-2 font-light separator"
          >
            <a routerLink="/planes" class="nav-link p-0 text-white">PLANES</a>
            <img
              role="button"
              routerLink="/planes"
              src="../../../../assets/images/svg/arrow-right.svg"
              class="d-sm-none"
            />
          </li>
          <li
            class="nav-item d-flex justify-content-between mb-2 pt-2 font-light separator"
          >
            <a
              routerLink="/lottery/keola-lovers"
              class="nav-link p-0 text-white"
              >KEOLA LOVERS</a
            >
            <img
              role="button"
              routerLink="/lottery/keola-lovers"
              src="../../../../assets/images/svg/arrow-right.svg"
              class="d-sm-none"
            />
          </li>
        </ul>
      </div>
      <div
        class="col-6 mb-4 d-flex flex-column flex-sm-column-reverse justify-content-end"
      >
        <div class="mt-4">
          <img
            src="../../../../assets/images/home/keola-lottery-white.png"
            alt="Logo Keola Lottery"
            class="logo-lottery"
          />
          <h6 class="font-light">RUC 205198484818</h6>
        </div>
        <div class="d-flex">
          <a
            href="https://www.facebook.com/keolalottery/"
            target="_blank"
            role="button"
          >
            <img
              src="../../../../assets/images/home/facebook-white.png"
              alt=""
              class="me-2"
              width="30"
          /></a>
          <a
            href="https://www.instagram.com/keolalottery/"
            target="_blank"
            role="button"
          >
            <img
              src="../../../../assets/images/home/instagram-white.png"
              alt=""
              class="me-2"
              width="30"
          /></a>
          <a
            href="https://www.tiktok.com/@keola_lottery"
            target="_blank"
            role="button"
            ><img
              src="../../../../assets/images/home/tik-tok-white.png"
              alt=""
              class="me-2"
              width="29"
          /></a>
        </div>
        <h6 class="font-bold mb-2 d-none d-sm-block">SÍGUENOS</h6>
      </div>
      <div
        class="col-6 d-flex justify-content-center align-items-center d-sm-none"
      >
        <a [href]="complaintsBookUrl" target="_blank">
          <img
            src="../../../../assets/images/libro-reclamaciones.png"
            alt=""
            class="libro-reclamaciones"
          />
        </a>
      </div>
      <div class="col d-block d-sm-none">
        <h6 class="mb-1">¿Necesitas ayuda?</h6>
        <p class="font-light">(021) 3352391 - (021) 239 1 222</p>
      </div>
      <div class="col d-none d-sm-block">
        <h6 class="font-bold d-none d-sm-block mb-2">CONTACTO</h6>
        <h6>¿Necesitas ayuda?</h6>
        <p class="font-light">(021) 3352391 - (021) 239 1 222</p>
      </div>
      <div
        class="col-6 d-none justify-content-center d-sm-flex align-items-start"
      >
        <a
          href="https://dev-soyaliadocomercial.keola.club/libro-de-reclamaciones?channel=LOTTERY"
          target="_blank"
        >
          <img
            src="../../../../assets/images/libro-reclamaciones.png"
            alt=""
            class="libro-reclamaciones"
          />
        </a>
      </div>
    </footer>
  </div>
</div>
