import {
  Component,
  OnInit,
  Renderer2,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { StorageService } from '../../../services/storage.service';
import { Router } from '@angular/router';
import { CulqiService } from '../../../services/culqi.service';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SweetAlertService } from '../../../services/sweet-alert.service';
import { PlainsService } from '../../../services/plains.service';
import { PaymentModalComponent } from '@components/modals/payment-modal/payment-modal.component';
import { ErrorModalComponent } from '@components/modals/error-modal/error-modal.component';
import { environment } from '../../../../environments/environment';
import {
  ICompleteBuyOdds,
  ICompleteBuyPay,
  ICurrentWallet,
  KeolaPayService,
  PaymentSource,
} from 'app/services/keola-pay.service';
import { GeneralService } from 'app/services/general.service';
import { PaymentOddsModalComponent } from '@components/modals/payment-odds-modal/payment-odds-modal.component';
import { Utils } from '@components/utils/utils';
import { PaymentIcons } from './types';

declare var Culqi: any;

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnInit, OnDestroy {
  planLotery: any;
  // myPayments = [
  //   {
  //     id: 1,
  //     name: 'Culqi',
  //     cardBrand: 'Culqi',
  //     lastNumbers: 0,
  //     icon: 'assets/images/culqi-logo.png',
  //   },
  //   {
  //     id: 2,
  //     name: 'Keola Pay',
  //     cardBrand: 'Keola Pay',
  //     lastNumbers: 0,
  //     icon: 'assets/images/svg/logo-keola-pay.svg',
  //   },
  //   {
  //     id: 3,
  //     name: 'Paypal',
  //     cardBrand: 'Paypal',
  //     lastNumbers: 0,
  //     icon: 'assets/images/logo-Paypal.png',
  //   },
  // ];
  myPayments = [];
  userCard: any;
  methodPay: string = 'Visa';
  amount: number;
  amountCulqi: number;
  country: any;
  currency: string;
  isYape: boolean = false;
  showSuccess: boolean = false;
  user: any;
  public payPalConfig?: IPayPalConfig;
  dataPayment: any;
  private paymentSuccessListener: () => void;
  private errorEnCargoListener: () => void;
  userWallet: any;
  userFullData: any;
  latestPlanId: number;
  latestPlanName: any;
  termsAccepted = false;
  isLoadingPayment = false;
  userPlans: any;
  operationNumber: any;
  noWallets: boolean = true;
  selectedWallet: ICurrentWallet;

  constructor(
    private storageService: StorageService,
    private generalService: GeneralService,
    private router: Router,
    private culqiService: CulqiService,
    public modalService: BsModalService,
    private renderer: Renderer2,
    private sweetAlert: SweetAlertService,
    private planService: PlainsService,
    private keolaPayService: KeolaPayService,
    private cdr: ChangeDetectorRef // Añadido para la detección de cambios
  ) {
    const storedCountry = this.storageService.get('country');
    this.country =
      typeof storedCountry === 'string'
        ? { name: storedCountry }
        : storedCountry;
    this.user = this.storageService.get('user');
  }

  ngOnInit() {
    this.user = this.storageService.get('user');
    // Si hay un usuario almacenado, obtiene información adicional
    if (this.user) {
      this.generalService.getUser().subscribe((res) => {
        this.userFullData = res.data;
        console.log('EL ID DEL USER ES', this.userFullData.id);
        // console.log('userFullData', res.data);
        // console.log('Planes del usuario', res.data.plansUser);
        this.userPlans = res.data.plansUser;

        let latestDate = new Date(0);
        if (this.userPlans) {
          this.userPlans.forEach((plan) => {
            let planEndData = new Date(plan.subscriptionEnds);
            if (planEndData > latestDate) {
              latestDate = planEndData;
              this.latestPlanId = plan.id;
              this.latestPlanName = plan.name;
            }
          });
        }

        console.log('***********', this.userFullData);
        this.keolaPayService
          .getWallets(this.userFullData.id)
          .subscribe((res) => {
            const walletsData = res.data.items[0].virtualWallets.map(
              (wallet: any) => ({
                accountType: wallet.accountType.accountTypeDescription,
                accountNumber: wallet.accountNumber,
                walletId: wallet.walletId,
                availableBalance: wallet.availableBalance,
                currency: wallet.currency.currencyCode,
                transactions: wallet.walletTransactions || [],
              })
            );

            const currencyToSelect =
              (this.userFullData.flag !== 'PE' ? 'USD' : null) || // PAGOS PARA PERU -> SOLES
              walletsData.find((w: any) => w.currency === 'PEN')?.currency || // Busca PEN
              walletsData.find((w: any) => w.currency === 'USD')?.currency || // Busca USD
              'PEN'; // Si no encuentra nada, usa PEN como último recurso

            const selectedWallet: ICurrentWallet = walletsData.find(
              (w: any) => w.currency === currencyToSelect
            );
            this.selectedWallet = selectedWallet;
            console.log('***SELECTED WALLET ES:', selectedWallet);
          });

        // console.log('latestPlanId', this.latestPlanId);
        // console.log('latestPlanName', this.latestPlanName);
      });
    }

    this.loadPaymentMethods();

    this.initConfigPaypal();
    this.subscribeToPaymentEvents();
    this.subscribeToPlanService();
  }

  ngOnDestroy() {
    this.unsubscribeFromPaymentEvents();
  }

  // Listando métodos de pago
  private loadPaymentMethods() {
    this.isLoadingPayment = true;
    this.generalService.getMethodPayments().subscribe((methods) => {
      const order = ['PAYTDC01', 'PAYYAPE01', 'PAYPAL01', 'PAYKEOLA01'];
      this.myPayments = methods
        .map((method) => ({
          id: method.columnId,
          name: method.columnDescription,
          code: method.code,
          // lastNumbers: method.columnId,
          icon: this.getIconPath(method.code),
        }))
        .sort((a, b) => order.indexOf(a.code) - order.indexOf(b.code));
      this.isLoadingPayment = false;
      this.cdr.detectChanges();
    });
  }

  private getIconPath(code: string): string {
    return PaymentIcons[code];
  }

  private subscribeToPaymentEvents() {
    this.paymentSuccessListener = this.renderer.listen(
      document,
      'paymentSuccess',
      this.handlePaymentSuccess.bind(this)
    );
    this.errorEnCargoListener = this.renderer.listen(
      document,
      'errorEnCargo',
      this.handleErrorEnCargo.bind(this)
    );
  }

  private unsubscribeFromPaymentEvents() {
    if (this.paymentSuccessListener) {
      this.paymentSuccessListener();
    }
    if (this.errorEnCargoListener) {
      this.errorEnCargoListener();
    }
  }

  private handlePaymentSuccess(event: any) {
    this.setLoadingPayment(true); // Start loading
    console.log('Payment success:', event.detail.success);
    const answer: any = event.detail.success;
    const params = {
      // Params para pago con Paypal y otros medios (Culqi, etc)
      paymentID: answer.id,
      paymentSource: this.userCard?.name,
      planId: this.latestPlanId,
      referenceCode: '',
      currency: 'USD',
      amount: this.dataPayment.totalAmountInDolars,
      probabilitys: this.dataPayment.probability,
    };
    this.planService.paymentPaypalOdds(params).subscribe(
      (res) => {
        console.log('Paypal RES:', res);
        // Se asignan los puntos de lotería
        this.assignLotteryPoints();
        this.setLoadingPayment(false); // Stop loading
        this.showPaymentModal(123456); // Test OP
      },
      (error) => {
        this.setLoadingPayment(false); // Start loading
        this.sweetAlert.toastError(error);
      }
    );
  }

  private setLoadingPayment(value: boolean) {
    this.isLoadingPayment = value;
    this.cdr.detectChanges(); // Forzar la detección de cambios
  }

  private handleErrorEnCargo(event: any) {
    console.error('Payment error:', event.detail.error.user_message);
    this.modalService.show(ErrorModalComponent, {
      initialState: {
        error: event.detail.error.user_message,
      },
    });
  }

  private subscribeToPlanService() {
    this.planService.dataPayment.subscribe((res) => {
      console.log('res', res);

      if (res) {
        this.dataPayment = res;
        console.log('dataPayment', this.dataPayment);
        this.getAmountAndConvert();
      } else {
        // console.warn('dataPayment is null or undefined');
        const fromLocal = localStorage.getItem('dataPayment');
        this.dataPayment = fromLocal ? JSON.parse(fromLocal) : null;
        console.log('dataPayment', this.dataPayment);
        // this.sweetAlert.toastWarning('No se encontraron datos de pago.');
      }
    });
  }

  changeMethod(evt: any) {
    console.log('Evt es:', evt.code);
    this.userCard = evt;
    this.methodPay = evt.code;
  }

  getAmountAndConvert() {
    this.amount = this.dataPayment?.totalAmountInDolars;
    this.currency = this.dataPayment?.currency;

    if (this.country.name === 'Perú') {
      console.log('Es peru');

      this.amountCulqi = Math.round(this.dataPayment?.totalAmount * 100);
      this.isYape = true;
    } else {
      console.log('Es usa');

      this.amountCulqi = Math.round(
        this.dataPayment?.totalAmountInDolars * 100
      );
      this.currency = 'USD';
      this.isYape = false;
    }
  }

  initConfigPaypal(): void {
    this.payPalConfig = {
      currency: 'USD',
      clientId: environment.paypalKey,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: this.amount.toFixed(2),
                breakdown: {
                  item_total: {
                    currency_code: 'USD',
                    value: this.amount.toFixed(2),
                  },
                },
              },
              items: [
                {
                  name: 'Enterprise Subscription',
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'USD',
                    value: this.amount.toFixed(2),
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onApprove: (data, actions) => {
        this.setLoadingPayment(true); // Start loading
        actions.order.get().then((details) => {
          console.log('Detalles del onApprove:', details);
          const answer: any = data;
          const params = {
            paymentID: answer.paymentID,
            paymentSource: answer.paymentSource,
            // planId: this.planLotery?.id, UPDATING PAYPAL PAYMENT
            planId: this.latestPlanId,
            referenceCode: '',
            currency: 'USD',
            amount: this.dataPayment.totalAmountInDolars,
            probabilitys: this.dataPayment.probability,
          };
          // Se compran Probabilidades con Paypal
          this.planService.paymentPaypalOdds(params).subscribe(
            (res) => {
              console.debug('Res', res);
              this.setLoadingPayment(false); // Stop loading
              // console.log('IS LOADING?', this.isLoadingPayment);
              if (!this.isLoadingPayment) {
                this.assignLotteryPoints();
                this.setLoadingPayment(false); // Stop loading
                this.showPaymentModal(123456); // Test OP
              }
            },
            (error) => {
              console.debug('Error', error);
              if (error) {
                this.sweetAlert.toastError(error);
                this.setLoadingPayment(false); // Stop loading
              }
            }
          );
        });
      },
      onClientAuthorization: (data) => {
        this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        this.setLoadingPayment(false); // Stop loading
      },
      onError: (err) => {
        console.log('OnError', err);
        this.setLoadingPayment(false); // Stop loading
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        this.setLoadingPayment(false); // Stop loading
      },
    };
  }

  generarNumeroAleatorio(): string {
    const numeroDecimal = Math.random();
    const numeroEntero = Math.floor(numeroDecimal * Math.pow(10, 9));
    return String(numeroEntero).padStart(9, '0');
  }

  openCheckout() {
    console.log(this.currency, this.amountCulqi, this.generarNumeroAleatorio());
    this.culqiService.removeScript(['culqi-checkout-v4', 'culqi-js-v4']);
    this.culqiService.loadScript({
      id: 'culqi-js-v4',
      url: environment.culqiScriptUrl,
    });
    Culqi.options({
      isCheckout: true,
      lang: 'auto',
      installments: false,
      paymentMethods: {
        tarjeta: true,
        yape: false,
        bancaMovil: false,
        agente: false,
        billetera: false,
        cuotealo: false,
      },
      // style: {
      //   logo: 'https://static.culqi.com/v2/v2/static/img/logo.png',
      // },
    });
    Culqi.settings({
      title: 'Keola Pagos',
      currency: this.currency, // Cambia esto según la moneda que estés utilizando
      amount: this.amountCulqi ? this.amountCulqi : 0,
      order: this.generarNumeroAleatorio(),
    });
  }

  openYape() {
    console.log(this.currency, this.amountCulqi, this.generarNumeroAleatorio());
    this.culqiService.removeScript(['culqi-checkout-v4', 'culqi-js-v4']);
    this.culqiService.loadScript({
      id: 'culqi-js-v4',
      url: environment.culqiScriptUrl,
    });
    Culqi.options({
      isCheckout: true,
      lang: 'auto',
      installments: false,
      paymentMethods: {
        tarjeta: false,
        yape: true,
        bancaMovil: false,
        agente: false,
        billetera: false,
        cuotealo: false,
      },
      // style: {
      //   logo: 'https://static.culqi.com/v2/v2/static/img/logo.png',
      // },
    });
    Culqi.settings({
      title: 'Keola Pagos',
      currency: this.currency, // Cambia esto según la moneda que estés utilizando
      amount: this.amountCulqi ? this.amountCulqi : 0,
      order: this.generarNumeroAleatorio(),
    });
  }

  // Mostrar el modal de Pago Exitoso
  showPaymentModal(operationNumber: any) {
    const user = this.storageService.get('user');
    const config: ModalOptions = {
      initialState: {
        planBuy: this.planLotery,
        operationNumber: operationNumber,
        transactionDate: Utils.formatDate(new Date()),
        paymentMethod: this.methodPay,
        amount:
          this.userFullData.flag === 'PE'
            ? (Math.ceil(this.dataPayment.totalAmount * 10) / 10).toFixed(2)
            : (
                Math.ceil(this.dataPayment.totalAmountInDolars * 10) / 10
              ).toFixed(2),
        currency: this.dataPayment.currency,
        purchaseOdds: this.dataPayment.probability,
        user: user,
      },
      class: 'modal-lg',
    };
    console.log('CONFIG:', config);

    this.modalService.show(PaymentOddsModalComponent, config);
  }

  handleKeolaOddsPayment() {
    this.setLoadingPayment(true);

    if (this.userFullData.flag === 'PE') {
      if (
        this.selectedWallet.availableBalance <
        Math.ceil(this.dataPayment.totalAmount * 10) / 10
      ) {
        this.sweetAlert.toastWarning('No tienes saldo Kpay suficiente');
        this.setLoadingPayment(false);

        return;
      }
    } else {
      if (
        this.selectedWallet.availableBalance <
        Math.ceil(this.dataPayment.totalAmountInDolars * 10) / 10
      ) {
        this.sweetAlert.toastWarning('No tienes saldo Kpay suficiente');
        this.setLoadingPayment(false);

        return;
      }
    }

    const transactionData = {
      userId: this.userFullData.id,
      walletId: this.selectedWallet.walletId,
      amount:
        this.userFullData.flag === 'PE'
          ? Math.ceil(this.dataPayment.totalAmount * 10) / 10
          : Math.ceil(this.dataPayment.totalAmountInDolars * 10) / 10,
      transactionType: 'PURCHASE', // transaction_type (master_column)
      paymentMethodCode: 'TXN001', // Código del método de pago
      paymentResponse: `Compra de Probabilidades`,
      currency: this.userFullData.flag === 'PE' ? 'PEN' : 'USD',
      status: 'COMPLETED', // Estado de la transacción
      source: 'LOTTERY', // Fuente de la transacción
    };

    // console.log('body', body);
    console.log('Estoy pagando probabilidades');

    this.keolaPayService.createTransaction(transactionData).subscribe(
      (response) => {
        this.handleWithdrawResponse(response);
        console.log('LA RESPUESTA DE CREATE TRANSACTION ES:', response);
      },
      (error) => {
        this.handleError(error, 'Error realizando el pago con Keola Pay');
      }
    );
  }

  // createWithdrawalRequestBody() {
  //   return {
  //     accountId: this.userWallet.id,
  //     withdrawalAmount: this.dataPayment.totalAmountInDolars,
  //     typeMovement: 7, // tipo de Movimiento para KEola PAY incremento prob 7
  //     currency: 'USD',
  //     userId: this.userFullData.id,
  //     planId: 0,
  //   };
  // }

  handleWithdrawResponse(response: any) {
    if (response.state) {
      this.operationNumber = response.data.walletTransactionId;
      const probabilityParams: ICompleteBuyOdds = {
        paymentID: response.data.operationNumber,
        paymentSource: 'KEOLA_PAY' as PaymentSource,
        referenceCode: '',
        planId: this.latestPlanId,
        currency: this.userFullData.flag === 'PE' ? 'PEN' : 'USD',
        amount:
          this.userFullData.flag === 'PE'
            ? Math.ceil(this.dataPayment.totalAmount * 10) / 10
            : Math.ceil(this.dataPayment.totalAmountInDolars * 10) / 10,
        probabilitys: this.dataPayment.probability,
      };
      console.log('probabilityParams', probabilityParams);

      this.keolaPayService.completeBuyProbability(probabilityParams).subscribe(
        (paymentResponse) => {
          this.setLoadingPayment(false);
          if (paymentResponse.state) {
            console.log('PAGO EXITOSO', paymentResponse);
            localStorage.removeItem('dataPayment');

            this.assignLotteryPoints();
            this.setLoadingPayment(false); // Stop loading
            this.showPaymentModal(this.operationNumber);
          } else {
            this.handleError(
              'Error completando la compra de probabilidades',
              paymentResponse
            );
            localStorage.removeItem('dataPayment');
          }
        },
        (error) => {
          this.handleError(
            'Error completando la compra de probabilidades',
            error
          );
          localStorage.removeItem('dataPayment');
        }
      );
    } else {
      this.handleError('Error en la respuesta del retiro', response);
    }
  }

  assignLotteryPoints() {
    this.keolaPayService
      .assignPointsLottery(this.userFullData.id, this.latestPlanId)
      .subscribe(
        (assignResponse) => {
          // Última request, se termina el proceso de pago
          console.log('Points assigned:', assignResponse.message);
          this.setLoadingPayment(false);
        },
        (error) => {
          // Última request, se termina el proceso de pago
          console.error('Error asignando puntos de lotería:', error);
          this.setLoadingPayment(false);
        }
      );
  }

  handleError(message: string, error: any) {
    console.error(message, error);
    this.sweetAlert.toastError(message);
    this.setLoadingPayment(false);
  }
}
