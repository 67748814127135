<div class="large-modal">
  <div class="bg-modal p-20 position-relative">
    <!-- <div class="d-flex align-items-center justify-content-end">
      <span class="color-4">N° Operación: {{ operationNumber }}</span>
    </div> -->
    <div class="resume-container">
      <div class="text-center mt-4">
        <div>
          <img
            src="assets/images/home/keola-lotery.png"
            class="img-pay mx-auto d-block"
          />
          <h2 class="color-primary font-bold ft-20 mt-4">
            Compraste los siguientes artículos
          </h2>
          <p class="color-4 ft-12">
            Hola {{ user.name }} , Gracias por tu compra
          </p>
        </div>

        <div class="items-bought">
          <div class="item-card" *ngFor="let item of purchaseOdds">
            <div class="item-image-container">
              <img [src]="item.img" alt="{{ item.name }}" class="item-image" />
              <div class="item-info">
                <p class="item-name">{{ item.name }}</p>
                <p class="item-probability">
                  Aumento de Probabilidad: +{{ item.options }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div>
          <div class="resume-col">
            <div class="resume-row">
              <span class="label">Código de la Orden:</span>
              <span class="value">N°00156-157</span>
            </div>
            <div class="resume-row">
              <span class="label">Valor:</span>
              <span class="value"> {{ currency }} {{ amount }} </span>
            </div>
            <div class="resume-row">
              <span class="label">Fecha de la compra:</span>
              <span class="value"> {{ transactionDate }} </span>
            </div>
            <div class="resume-row">
              <span class="label">Forma de pago:</span>
              <span class="value"> {{ paymentMethod }} </span>
            </div>
            <div class="resume-row">
              <span class="label">Email de la compra:</span>
              <span class="value"> {{ user.email }} </span>
            </div>
          </div>
        </div>
        <button (click)="closeModal()" class="btn btn-login mt-4">
          Aceptar
        </button>
      </div>
    </div>
    <div class="footer">
      <div class="contact-info">
        <p>
          <img src="assets/images/svg/phone.svg" alt="Phone Icon" /> +51 999 888
          777
        </p>
        <p>
          <img src="assets/images/svg/mail.svg" alt="Email Icon" />
          keOla&#64;gmail.com
        </p>
      </div>
      <div class="logo">
        <img
          src="assets/images/svg/keola-lottery.svg"
          alt="Keola Lottery Logo"
        />
      </div>
    </div>
  </div>
</div>
